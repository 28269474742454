<template lang="pug">
	.side-menu
		ul.menu(v-if="user")
			li(v-for="item in menuItems", :class="{'expanded active':item===activeMenu}", v-bind:key="item.name", v-if="!item.permission || user.permissions[item.permission]")
				router-link(v-if="item.link", :to="item.link")
					i.la(:class="'la-'+item.icon")
					| {{ item.title }}
					new-feature(v-if="item.new")
				a(v-else, href="javascript:void(0)", @click="toggleMenu(item)")
					i.la(:class="'la-'+item.icon")
					| {{ item.title }}
					span.toggle-arrow.la.la-angle-up(v-if="item.children")
				ul(v-if="item.children")
					li(v-for="subItem in item.children", v-bind:key="subItem.name", v-if="!subItem.permission || user.permissions[subItem.permission]")
						router-link(:to="{name:subItem.name}") {{ subItem.title }}
							new-feature(v-if="subItem.new")
</template>
<script>
export default {
  name: 'SideMenu',
  methods: {
    toggleMenu(item) {
      if (this.activeMenu === item) {
        this.activeMenu = null;
      } else {
        this.activeMenu = item;
      }
    },
  },
  mounted() {
    let user = this.$store.state.user;
    if (user.config) {
      for (let feature in user.config) {
        user.permissions[`feature::${feature || ''}`] = true;
      }
    }
    this.user = user;
  },
  data() {
    return {
      user: null,
      activeMenu: null,
      menuItems: [
        // {
        // 	title: 'Dashboard',
        // 	icon: 'home',
        // 	name: 'dashboard',
        // 	link: '/app/dashboard',
        // },
        {
          title: 'Demand',
          icon: 'exchange-alt',
          children: [
            {
              title: 'Advertiser',
              name: 'advertiser-list',
              link: '/app/advertisers',
              permission: 'advertisers',
            },
            {
              title: 'Offers',
              name: 'offer-list',
              link: '/app/offers',
              permission: 'offers',
            },
            {
              title: 'External Offers',
              name: 'external-offers',
              link: '/app/external-offers',
              permission: 'external_offers',
            },
            {
              title: 'MMP Accounts',
              name: 'mmp-account-list',
              link: '/app/mmp-accounts',
            },
            // {
            // 	title: 'Apps',
            // 	name: 'apps',
            // 	link: '/app/apps',
            // 	permission: 'admin_only',
            // }
          ],
        },
        {
          title: 'Supply',
          icon: 'exchange-alt',
          children: [
            {
              title: 'Publishers',
              name: 'publisher-list',
              link: '/app/publishers',
              permission: 'publishers',
            },
            {
              title: 'Placements',
              name: 'placement-list',
              link: '/app/placements',
              permission: 'placements',
            },
            {
              title: 'Tags',
              name: 'tags',
              link: '/app/tags',
            },
          ],
        },
        {
          title: 'Performance',
          icon: 'area-chart',
          children: [
            // {
            // 	title: 'Statistics',
            // 	name: 'statistics',
            // 	link: '/app/statistics',
            // },
            {
              title: 'Reports',
              name: 'reports',
              link: '/app/reports',
            },
            {
              title: 'Conversions',
              name: 'conversions',
              link: '/app/conversions',
            },
            {
              title: 'Publisher Conv.',
              name: 'pub_conversions',
              link: '/app/pub_conversions',
            },
            {
              title: 'Events',
              name: 'events',
              link: '/app/events',
            },
            {
              title: 'Rejections',
              name: 'rejected',
              link: '/app/rejected',
            },
            // {
            // 	title: 'KPI Rules',
            // 	name : 'kpi-rules',
            // 	link : '/app/kpi-rules',
            // },
          ],
        },
        {
          title: 'Lookup',
          icon: 'search',
          children: [
            // {
            // 	title: 'Statistics',
            // 	name: 'statistics',
            // 	link: '/app/statistics',
            // },
            // {
            // 	title: 'Clicks',
            // 	name: 'clicks',
            // 	link: '/app/clicks',
            // },
            {
              title: 'Clicks',
              name: 'click-search',
              link: '/app/click-search',
            },
            {
              title: 'Post-attribution reports',
              name: 'pa-reports',
              link: '/app/pa-reports',
            },
            {
              title: 'Conversion Reports',
              name: 'conversion-reports',
              link: '/app/conversion-reports',
              new: true,
            },
            {
              title: 'URL Scanner',
              name: 'url-scanner',
              link: '/app/url-scanner',
              new: true
            },
            // {
            // 	title: 'Subsources',
            // 	icon: 'list',
            // 	name: 'subsources',
            // 	link: '/app/subsources',
            // },
          ],
        },
        {
          title: 'Finance',
          icon: 'dollar',
          permission: 'admin_only',
          children: [
            {
              title: 'Legal Entities',
              name: 'legal-entity-list',
              link: '/app/finance/legal-entities',
            },
            {
              title: 'Advertisers',
              name: 'finance-advertisers',
              link: '/app/finance/advertisers',
            },
            {
              title: 'Publishers',
              name: 'finance-publishers',
              link: '/app/finance/publishers',
            },
            {
              title: 'Summary',
              name: 'finance-list',
              link: '/app/finance/summary-list',
            },
            // {
            // 	title: 'Documents',
            // 	name: 'documents',
            // 	link: '/app/finance/documents'
            // },
          ],
        },
        // {
        //   title: 'Location',
        //   icon: 'map-marked-alt',
        //   name: 'data',
        //   children: [
        //     // {
        //     // 	title: 'SSPs',
        //     // 	name: 'ssp',
        //     // 	link: '/app/ssp',
        //     // },
        //     // {
        //     // 	title: 'Exchanges',
        //     // 	name: 'exchanges',
        //     // 	link: '/app/exchanges',
        //     // },
        //     {
        //       title: 'Countries',
        //       name: 'countries',
        //       link: '/app/countries',
        //     },
        //     {
        //       title: 'Regions',
        //       name: 'regions',
        //       link: '/app/regions',
        //     },
        //     {
        //       title: 'Cities',
        //       name: 'cities',
        //       link: '/app/cities',
        //     },
        //     {
        //       title: 'IPs',
        //       name: 'ipinfo',
        //       link: '/app/ipinfo',
        //     },
        //     // {
        //     // 	title: 'Carriers',
        //     // 	name: 'carriers',
        //     // 	link: '/app/carriers',
        //     // },
        //     // {
        //     // 	title: 'Sources',
        //     // 	name: 'sources',
        //     // 	link: '/app/sources',
        //     // },
        //     // {
        //     // 	title: 'Devices',
        //     // 	name: 'devices',
        //     // 	link: '/app/devices',
        //     // },
        //     // {
        //     // 	title: 'App Preview',
        //     // 	name: 'app-preview',
        //     // 	link: '/app/app-preview',
        //     // },
        //     // {
        //     // 	title: 'OS',
        //     // 	name: 'os',
        //     // 	link: '/app/os',
        //     // },
        //     // {
        //     // 	title: 'Browsers',
        //     // 	name: 'browsers',
        //     // 	link: '/app/browsers',
        //     // },
        //     // {
        //     // 	title: 'Browser Versions',
        //     // 	name: 'browser-versions',
        //     // 	link: '/app/browser-versions',
        //     // },
        //   ],
        // },

        {
          title: 'Settings',
          icon: 'cogs',
          permission: 'admin_only',
          children: [
            {
              title: 'Users',
              name: 'user-list',
              link: '/app/users',
            },
            {
              title: 'System Settings',
              name: 'settings',
              link: '/app/settings',
            },

            /**
             * @link https://app.clickup.com/t/86er6tj18 Fallback disabled for now
             */
            // {
            //   title: 'Smartlink',
            //   name: 'offer-fallback',
            //   link: '/app/fallback',
            //   permission: 'offers',
            // },

            {
              title: 'KPI Rules',
              name: 'kpi-rules',
              link: '/app/kpi-rules',
            },
            {
              title: 'Terms',
              name: 'terms',
              link: '/app/terms',
            },
            {
              title: 'Devices Blacklist',
              name: 'devices-blacklist',
              link: '/app/devices-blacklist',
              permission: 'feature::deviceBL',
            },
            {
              title: 'Reports',
              name: 'reports-settings',
              link: '/app/reports-settings',
            },
          ],
        },

        {
          title: 'Monitor',
          icon: 'server',
          permission: 'admin_only',
          children: [
            // {
            // 	title: 'Clicks',
            // 	name: 'monitor-clicks',
            // 	link: '/app/monitor',
            // },
            {
              title: 'Postback Log',
              name: 'postback-log',
              link: '/app/postback-log',
            },
            {
              title: 'Publisher Postback',
              name: 'publisher-postbacks',
              link: '/app/publisher-postbacks',
            },
            {
              title: 'Activity Log',
              name: 'activity-log',
              link: '/app/activity-log',
            },
            {
              title: 'KPI Rules Log',
              name: 'kpi-rule-log',
              link: '/app/kpi-rule-log',
            },
            // {
            // 	title: 'Request Storage',
            // 	name: 'monitor-request-storage',
            // 	link: '/app/request-storage',
            // },
          ],
        },
        {
          title: 'Support',
          icon: 'question',
          name: 'support',
          link: '/app/support',
        },
        // {
        // 	title: 'Documentation',
        // 	icon: 'book',
        // 	name: 'docs',
        // 	link: '/docs',
        // },
        // {
        // 	title: 'Settings',
        // 	icon: 'cogs',
        // 	name: 'settings',
        // 	link: '/app/settings',
        // },
      ],
    };
  },
};
</script>
