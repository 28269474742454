<template lang="pug">
  div.default-modal
    .modal-header
      .modal-title Adjust URL Helper
      a.close(href="javascript:void(0);", @click="$emit('close')")
        i.la.la-times
    .modal-body
      .modal-body-inner
        form(@submit.prevent="submit")
          table.event-list.table
            thead
              tr
                th &nbsp;
                th Event Name
                th Adjust Token
            tbody
              tr
                td
                  b-form-checkbox(v-model="installPB")
                td Install
                td &nbsp;

              tr(v-for="(e, i) in events")
                td
                  b-form-checkbox(v-model="e._selected")
                td {{e.name}}
                td.event-list-item
                  input.form-control(type="text", placeholder="Token", v-model="e.token")
              tr
                td
                  b-form-checkbox(v-model="rejectPB")
                td Reject
                td &nbsp;

          label Custom Postback Domain
            select.form-control(v-model="pbBaseURL")
              option(v-for="d in postbackDomains" :value="d.value") {{ d.label }}

          .buttons.text-right
            button.btn.btn-primary(type="submit") Set URL


</template>
<script>
export default {
  name: 'AdjustURLHelper',
  props: ['offer', 'url', 'pbBaseURL', 'secret'],
  computed: {
    postbackDomains() {
      return this.$store.state.user.postbackDomains;
    },
  },
  data() {
    return {
      events: [],
      /** @type {URL} */
      urlObj: null,
      baseUrl: '',
      params: [],
      installPB: false,
      rejectPB: false,
      newURL: false,
    };
  },
  mounted() {
    let offerEvents = this.offer.events.map((e) => ({
      name: e.name,
      payout: e.payout,
      _selected: false,
      token: '',
    }));
    this.urlObj = new URL(this.url);

    this.urlObj.searchParams.forEach((value, name, searchParams) => {
      if (name.startsWith('event_callback')) {
        let p = name.split('_');
        let token = p.length === 3 ? p[2] : '';
        if (!value.includes('https://')) {
          value = 'https://' + value;
        }
        let eventPB = new URL(value);
        let eventName = eventPB.searchParams.get('event_name');
        console.log(token, eventName);
        let e = offerEvents.find((e) => e.name === eventName);
        if (e) {
          e.token = token;
          e._selected = true;
        } else {
          offerEvents.push({
            payout: 0,
            name: eventName,
            token: token,
            _selected: true,
          });
        }
      }
      if (name === 'install_callback') {
        this.installPB = true;
      }
      if (name === 'rejected_install_callback') {
        this.rejectPB = true;
      }

      // Set the base URL for postback in selector
      const isCallback = name === 'event_callback'
        || name === 'install_callback'
        || name === 'rejected_install_callback';
      if (isCallback) {
        this.pbBaseURL = `${new URL(value).origin}/`
      }
    });

    offerEvents.forEach((e) => {
      this.events.push({
        name: e.name,
        payout: e.payout,
        _selected: e._selected,
        token: e.token,
      });
    });
  },

  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      let errs = [];
      this.events.forEach((e) => {
        if (e._selected && e.token.length !== 6) {
          let errMessage = `${e.name} token is invalid`;
          this.$ovNotify.error(errMessage);
          errs.push(errMessage);
        }
      });
      if (errs.length > 0) {
        console.error(errs);
        return;
      }

      let parsedUrl = this.url.split('?', 2);
      let newUrl = parsedUrl[0] + '?';
      let urlParams = [];
      this.newURL = new URL(parsedUrl[0]);
      this.urlObj.searchParams.forEach((value, name, searchParams) => {
        if (
          !name.startsWith('event_callback') &&
          name !== 'install_callback' &&
          name !== 'rejected_install_callback'
        ) {
          urlParams.push(`${name}=${value}`);
        }
      });

      let mmpAccountId = this.offer.mmp_account_id || null;
      let key, cb, baseUrl;
      if (this.installPB) {
        baseUrl = mmpAccountId
          ? `${this.pbBaseURL}e/all/${mmpAccountId}`
          : `${this.pbBaseURL}e/all`;
        cb =
          baseUrl +
          `?clickid={clickid}&secret=${this.secret}&postback_id={nonce}&click_ts={click_time}&event_name=install`;
        key = 'install_callback';
        urlParams.push(`${key}=${encodeURIComponent(cb)}`);
      }
      this.events
        .filter((e) => e._selected)
        .forEach((e) => {
          baseUrl = mmpAccountId
            ? `${this.pbBaseURL}e/all/${mmpAccountId}`
            : `${this.pbBaseURL}e/all`;
          key = `event_callback_${e.token}`;
          cb =
            baseUrl +
            `?clickid={clickid}&secret=${this.secret}&postback_id={nonce}&click_ts={click_time}&event_name=${e.name}`;
          urlParams.push(`${key}=${encodeURIComponent(cb)}`);
        });
      if (this.rejectPB) {
        baseUrl = mmpAccountId
          ? `${this.pbBaseURL}er/all/${mmpAccountId}`
          : `${this.pbBaseURL}er/all`;
        cb =
          baseUrl +
          `?clickid={clickid}&secret=${this.secret}&blocked_reason={rejection_reason}&postback_id={nonce}`;
        key = 'rejected_install_callback';
        urlParams.push(`${key}=${encodeURIComponent(cb)}`);
      }

      newUrl += urlParams.join('&');

      // decode click id so that it will be replaced before sent to Adjust
      newUrl = newUrl.replaceAll('clickid%3D%7Bclickid%7D', 'clickid%3D{clickid}');

      this.busy = true;
      try {
        console.log('newURL', newUrl);
        localStorage.setItem('adjustUrl', newUrl);
        this.$emit('close');
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
