<template lang="pug">
.form-group
  .d-flex.gap-2.align-items-center
    label.m-0 Impressions rate
    b-checkbox(switch="", :checked="value >= 0", @change="toggle")
  input.form-control(v-if="!turnedOff", type="text", placeholder="No change", v-model="displayValue", @blur="isInputActive=false", @focus="isInputActive=true")
  p.form-text.text-muted(:class="{invalid: inputInvalid}") {{ ratioText }}
</template>
<style lang="scss">
p.form-text.invalid {
  color: #9b0000 !important;
}
</style>
<script>
export default {
  name: 'ImpressionRatio',
  props: {
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isInputActive: false,
      inputInvalid: false,
    };
  },
  methods: {
    toggle() {
      const newValue = this.value === -1 ? 0 : -1;
      this.$emit('input', newValue);
    }
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          /** Cursor is inside the input field. unformat display value for user */
          return this.value.toString();
        } else {
          /** User is not modifying now. Format display value for user interface */
          return this.value + '%';
        }
      },
      /** @param {number} modifiedValue */
      set: function (modifiedValue) {
        let stringValue = modifiedValue.toString();
        let [whole, fraction] = stringValue.split('.');
        if (fraction && fraction.length > 2) {
          stringValue = whole + '.' + fraction.slice(0, 2);
        }

        // eslint-disable-next-line no-useless-escape
        let newValue = parseFloat(stringValue.replace(/[^\d\.]/g, ''));
        /** Ensure that it is not NaN */
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.inputInvalid = newValue < -1 || newValue > 100;

        /** Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component */
        /** $emit the event so that parent component gets it */
        this.$emit('input', newValue);
      },
    },
    ratioText() {
      let percentage = this.value;
      if (percentage === -1) {
        return 'No change (as sent by publisher)';
      }
      if (percentage < -1 || percentage > 100) {
        return 'Invalid value';
      }
      if (percentage === 100) {
        return 'Only impressions';
      }
      if (percentage === 0) {
        return 'Only clicks';
      }
      if (percentage > 50) {
        let clickRatio = 1 / ((100 - percentage) / 100) - 1;
        return `${parseFloat(clickRatio.toFixed(2))} impressions per click`;
      } else {
        let impressionRatio = 1 / (percentage / 100) - 1;
        return `${parseFloat(impressionRatio.toFixed(2))} clicks per impression`;
      }
    },

    turnedOff() { return this.value === -1 }
  },
};
</script>
