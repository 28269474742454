<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Edit Placement <span v-if="placement">({{ placement.id }})</span>
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form(@submit.prevent="submit", v-if="placement")

					.row(v-if="!caps_only")
						.col-sm-6
							.form-group
								label Status
								select.form-control(v-model="placement.status", name="status")
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}

					h4.widget-section-title Caps
					.row(v-if="!useClicksRatio")
						.col-sm-6
							.form-group
								.d-flex.justify-content-between
									label Click Cap
									b-checkbox(
										v-if="USER.config.clickRatioCap && placement.publisher.media_type !== 'rewarded'",
										switch,
										v-model="useClicksRatio",
										v-b-tooltip,
										title="Caps should be fixed or automatic based on a traffic"
									)
								number-input(v-model="placement.daily_click_cap")
						.col-sm-6
							.form-group
								label Impression Cap &nbsp;
									i.la.la-info-circle(v-if="placement.daily_click_cap > 0 && !placement.daily_impression_cap", v-b-tooltip.hover.right, title="There is no cap on impressions, but once the click cap is reached placement will be paused")
								number-input(v-model="placement.daily_impression_cap")
					.row(v-else)
						.col-sm-12
							ClickRatio(v-model="placement.click_ratio_cap", :useClicksRatio="useClicksRatio", @disable="useClicksRatio = false")

					.row
						.col-sm-6
							.form-group
								label Conversion Cap
								number-input(v-model="placement.daily_conversion_cap")
								div Offer: {{ placement.offer.daily_conversion_cap }}
								div Effective Risk: {{ effective_risk }} %
								div Visible Cap: {{ visible_cap }}
								div Conversion: {{ conversion }}
						.col-sm-6
							.form-group
								label Daily Install Cap
								number-input(v-model="placement.daily_install_cap")
								div Offer: {{ placement.offer.daily_install_cap }}

					h4.widget-section-title Pacing
					.row
						.col-sm-6
							.form-group
								label Click pacing (Minute)
								number-input(v-model="placement.click_pacing")
								p.form-text.text-muted(v-if="!placement.click_pacing") No limit of clicks / minute
								p.form-text(v-if="placement.click_pacing") Max {{placement.click_pacing | numberCommas }} clicks / minute
						.col-sm-6
							.form-group
								label Click pacing (Hour)
								number-input(v-model="placement.click_pacing_hour")
								p.form-text.text-muted(v-if="!placement.click_pacing_hour") No limit of clicks / hour
								p.form-text(v-if="placement.click_pacing_hour") Max {{placement.click_pacing_hour | numberCommas }} clicks / hour
					.row
						.col-sm-6
							.form-group
								label Impression pacing (Minute)
								number-input(v-model="placement.impression_pacing")
								p.form-text.text-muted(v-if="!placement.impression_pacing") No limit of impressions / minute
								p.form-text(v-if="placement.impression_pacing") Max {{placement.impression_pacing | numberCommas }} impressions / minute
						.col-sm-6
							.form-group
								label Impression pacing (Hour)
								number-input(v-model="placement.impression_pacing_hour")
								p.form-text.text-muted(v-if="!placement.impression_pacing_hour") No limit of impressions / hour
								p.form-text(v-if="placement.impression_pacing_hour") Max {{placement.impression_pacing_hour | numberCommas }} impressions / hour

					h4.widget-section-title.mt-2 Payout
					.row
						.col-sm-6
							label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.auto_payout", :width="40", :height="20", :font-size="14")
									span.lbl Automatic payout
						.col-sm-6
							.form-group
								label Payout Mode
								select.form-control(v-model="placement.payout_mode")
									option(value="fixed") Fixed
									option(value="dynamic") Dynamic
					.row
						.col-sm-6
							.form-group
								label Payout
								input.form-control(type="text", placeholder="0.00", v-model="placement.payout")
								p.form-text Offer payout: <span v-if="offer">{{ offer.payout }}</span>
						.col-sm-6
							.form-group
								label Currency
								select.form-control(v-model="placement.currency")
									option(v-for="o in currencyOptions", :value="o.v", :disabled="o.disabled") {{ o.t }}
					.row
						.col-sm-6
							.form-group
								label Payout Model
								select.form-control(v-model="placement.payout_type")
									option(v-for="o in payoutTypeOptions", :value="o.v") {{ o.v }}
								p.form-text Offer payout type: <span v-if="offer">{{ offer.payout_type }}</span>

						.col-sm-6(v-if="placement.payout_type === 'CPA'")
							.form-group
								label Payable Event
								select.form-control(v-model="placement.payable_event_name")
									option(v-for="o in offer.events", :value="o.name") {{ o.name }}
									option(v-if="offer_payable_event", :value="null") -- As defined in the offer ({{offer_payable_event}}) --
								p.form-text(v-if="offer_payable_event") Offer payable event: {{ offer_payable_event }}
							.form-group
								b-form-checkbox(v-model="placement.allow_multiple_conversions") Allow multiple conversions for the same click

					div(v-if="USER.permissions['placements::RISK'] && !caps_only")
						h4.widget-section-title Risk
						.row
							.col-sm-4
								.form-group
									label &nbsp;
									div.toggle-wrapper
										toggle-button(v-model="placement.custom_report", :width="40", :height="20", :font-size="14")
										span.lbl Override auto-report
											i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="When enabled - publisher's risk is ignored and determined by this configuration")
							.col-sm-8(v-if="placement.custom_report")
								.form-group
									label Risk Management
									.input-group
										input.form-control(type="text", placeholder="", v-model.number="placement.risk_management")
										div.input-group-append
											span.input-group-text %

						p.clearfix &nbsp;
						.row
							.col-sm-6
								.form-group
									label Risk mode
									select.form-control(v-model="placement.risk_calc_mode")
										option(value="normal") From Date
										option(value="daily") Daily

							.col-sm-6(v-if="placement.risk_calc_mode === 'normal'")
								.form-group
									label Calculate risk from
									.input-group
										input.form-control(type="text", v-model="placement.risk_calc_from", placeholder="Calculate from")
										div.input-group-append
											a.input-group-text(href="javascript:void(0)", @click="setCalcFromNow()")
												i.la.la-clock

					h4.widget-section-title Ratio
					.row
						.col-sm-6
							.form-group
								impression-ratio(v-model="placement.impression_rate")

					h4.widget-section-title Other
					.row
						.col
							.form-group
								label Comments
								textarea.form-control(rows="3", placeholder="Comments (Internal use)", v-model="placement.comments")

					p.clearfix &nbsp;
					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import ClickRatio from '../../components/form/ClickRatio.vue';

export default {
	name: 'QuickEditPlacementModal',
	props: ['id', 'caps_only'],
	components: { ClickRatio },
	methods: {
		setCalcFromNow() {
			this.placement.risk_calc_from = moment().format('DD/MM/YYYY HH:mm');
		},
		async submit() {
			if (this.busy) {
				return;
			}
			let data = Vue.util.extend({}, this.placement);
			Vue.ovData.placement._booleans.forEach((f) => {
				data[f] = data[f] ? 1 : 0;
			});
			this.busy = true;
			try {
				await this.$ovReq.post('placement/quickSave', data);
				this.$ovNotify.success('Placement has been saved');
				this.$emit('close');
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		},
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		effective_risk() {
			return this.placement.custom_report
				? this.placement.risk_management
				: this.placement.publisher.auto_report_risk;
		},
		visible_cap() {
			return Math.floor(
				(this.effective_risk / 100) *
				(this.placement.daily_conversion_cap || this.placement.offer.daily_conversion_cap),
			);
		},
		conversion() {
			const payoutType = this.placement.payout_type;
			if (payoutType === 'CPA') return this.placement.payable_event_name;
			if (payoutType === 'CPI') return 'Install';
			return '';
		},

		currencyOptions() { return Vue.ovData.general.currencyOptions },
		payoutTypeOptions() { return Vue.ovData.general.payoutTypeOptions },
	},
	data() {
		return {
			busy: false,
			placement: null,
			offer: null,
			statusOptions: [
				{ v: 'draft', t: 'Draft' },
				{ v: 'live', t: 'Live' },
				{ v: 'paused', t: 'Paused' },
				{ v: 'cancelled', t: 'Cancelled' },
				// {v: 'ended', t: 'Ended'}
			],
			useClicksRatio: false,
		};
	},
	async created() {
		this.busy = true;
		try {
			this.placement = await this.$ovData.placement.get(this.id, true);
			this.offer = this.placement.offer;
			this.useClicksRatio = this.placement.click_ratio_cap > 0;
		} catch (e) {
			console.error(e);
			this.$emit('close');
		}
		this.busy = false;
	},
};
</script>
