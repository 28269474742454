<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Filtered Subsources
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				.stats-table-wrapper
					table.table.table-bordered
						thead
							tr
								th Subsource
								th Action
						tbody
							tr(v-for="(r, idx) in subsources", v-bind:key="r.id")
								td {{ r.name }}
								td
									button.btn.btn-danger.mr-1(type="button", @click="remove(idx)",
										v-b-tooltip.hover.left, title="Remove from filtered list")
										i.la.la-trash
</template>
<script>
export default {
  name: 'SubsourceListModal',
  props: ['subsources'],
  data() {
    return {
      busy: false
    };
  },
  methods: {
    /** @param {number} idx */
    remove(idx) {
      console.log('remove', idx);
      this.subsources.splice(idx, 1);
    }
  }
};
</script>
