/**
 * @typedef {Object} activityLogField
 * @property {string} label Field label
 * @property {'list'
 * | 'kvMap'
 * | 'boolean'
 * | 'events'
 * | 'currency'
 * | 'platform'
 * | 'capitalize'
 * | 'postbacks'} [type] Field type. If not specified, value will be treated as a string
 * @property {boolean} [ignore] If true, field won't be shown in the table
 */

/** @type {Record<string, activityLogField>} */
const activityLogFieldsMap = {
  status: {
    label: 'Status',
    type: 'capitalize',
  },
  risk_management: {
    label: 'Risk Management',
  },
  payout_type: {
    label: 'Payout Type',
  },
  comments: {
    label: 'Comments',
  },
  user_id: {
    label: 'User ID',
  },
  name: {
    label: 'Name',
  },
  daily_click_cap: {
    label: 'Daily Click Cap',
  },
  daily_install_cap: {
    label: 'Daily Install Cap',
  },
  tracking_url: {
    label: 'Tracking URL',
  },
  payout: {
    label: 'Payout',
    type: 'currency',
  },
  geo_targeting: {
    label: 'Geo Targeting',
    //     whitelist:
    // - Israel
    //        - Tel Aviv
    //        - Jerusalem
  },
  geo_targeting_hash: {
    label: 'Geo Targeting Hash',
    ignore: true,
  },
  platform: {
    label: 'Platform',
    type: 'platform',
  },
  country: {
    label: 'Country',
  },
  custom_params: {
    label: 'Custom Params',
  },
  app_name_mode: {
    label: 'App Name Mode',
  },
  bundle_id_mode: {
    label: 'Bundle ID Mode',
  },
  subsource_mode: {
    label: 'Subsource Mode',
  },
  referer_mode: {
    label: 'Referer Mode',
  },
  deviceid_mode: {
    label: 'Device ID Mode',
  },
  distribution_format: {
    label: 'Distribution Format',
  },
  impression_pacing: {
    label: 'Impression Pacing',
  },
  impression_pacing_hour: {
    label: 'Impression Pacing Hour',
  },
  click_pacing: {
    label: 'Click Pacing',
  },
  click_pacing_hour: {
    label: 'Click Pacing Hour',
  },
  weekly_conversion_cap: {
    label: 'Weekly Conversion Cap',
  },
  daily_conversion_cap: {
    label: 'Daily Conversion Cap',
  },
  daily_impression_cap: {
    label: 'Daily Impression Cap',
  },
  monthly_budget: {
    label: 'Monthly Budget',
  },
  daily_budget: {
    label: 'Daily Budget',
  },
  deviceid_blacklist_id: {
    label: 'Device ID Blacklist ID',
  },
  block_pixels: {
    label: 'Block Pixels',
    type: 'boolean',
  },
  block_proxies: {
    label: 'Block Proxies',
    type: 'boolean',
  },
  block_iframe: {
    label: 'Block iFrame',
    type: 'boolean',
  },
  normalize_bundleid: {
    label: 'Normalize Bundle ID',
    type: 'boolean',
  },
  lock_conversion_caps: {
    label: 'Lock Conversion Caps',
    type: 'boolean',
  },
  lock_caps: {
    label: 'Lock Caps',
    type: 'boolean',
  },
  hide_caps: {
    label: 'Hide Caps',
    type: 'boolean',
  },
  custom_params_list: {
    label: 'Custom Params List',
  },
  impression_rate: {
    label: 'Impression Rate',
  },
  frequency_cap_params: {
    label: 'Frequency Cap Params',
  },
  require_validation: {
    label: 'Require Validation',
    type: 'boolean',
  },
  allow_incent: {
    label: 'Allow Incent',
    type: 'boolean',
  },
  hard_kpi: {
    label: 'Hard KPI',
    type: 'boolean',
  },
  store_package_id: {
    label: 'Store Package ID',
  },
  store_bundle_id: {
    label: 'Store Bundle ID',
  },
  preview_url: {
    label: 'Preview URL',
  },
  is_direct_link: {
    label: 'Is Direct Link',
    type: 'boolean',
  },
  impression_url: {
    label: 'Impression URL',
  },
  lock_tracking_url: {
    label: 'Lock Tracking URL',
    type: 'boolean',
  },
  kpi: {
    label: 'KPI',
  },
  user_flow: {
    label: 'User Flow',
  },
  short_description: {
    label: 'Short Description',
  },
  description: {
    label: 'Description',
  },
  restriction_hash: {
    label: 'Resriction Hash',
    ignore: true,
  },
  restriction_list: {
    label: 'Restriction List',
    type: 'list',
  },
  date_end: {
    label: 'Date End',
  },
  date_start: {
    label: 'Date Start',
  },
  event_convert_payout_only: {
    label: 'Event Convert Payout Only',
    type: 'boolean',
  },
  auto_payout: {
    label: 'Auto Payout',
    type: 'boolean',
  },
  report_retention: {
    label: 'Report Retention',
    type: 'boolean',
  },
  events: {
    label: 'Events',
    type: 'events',
    // - jump (Jump) - 1$ fixed
  },
  debug: {
    label: 'Debug',
    type: 'boolean',
  },
  allow_multiple_conversions: {
    label: 'Allow Multiple Conversions',
    type: 'boolean',
  },
  sub1_partial_match_count: {
    label: 'Sub1 Partial Match Count',
  },
  risk_calc_from: {
    label: 'Risk Calc From',
  },
  restrict_timeframe: {
    label: 'Restrict Timeframe',
    type: 'boolean',
  },
  sub1_whitelist: {
    label: 'Sub1 Whitelist',
  },
  sub1_blwl_mode: {
    label: 'Sub1 BLWL Mode',
    type: 'capitalize',
  },
  default_subsource_cap: {
    label: 'Default Subsource Cap',
  },
  redirect_type: {
    label: 'Redirect Type',
  },
  risk_calc_mode: {
    label: 'Risk Calc Mode',
    type: 'capitalize',
  },
  custom_report: {
    label: 'Custom Report',
    type: 'boolean',
  },
  report_on_overcap: {
    label: 'Report On Overcap',
    type: 'boolean',
  },
  report_offer_closed: {
    label: 'Report Offer Closed',
    type: 'boolean',
  },
  payout_mode: {
    label: 'Payout Mode',
    type: 'capitalize',
  },
  override_name: {
    label: 'Override Name',
  },
  sub1_whitelist_hash: {
    label: 'Sub1 Whitelist Hash',
    ignore: true,
  },
  sub1_whitelist_list: {
    label: 'Sub1 Whitelist List',
    type: 'list',
  },
  restrict_timeframe_schedule: {
    label: 'Restrict Timeframe Schedule',
  },
  default_event_convert_payout: {
    label: 'Default Event Convert Payout',
    type: 'boolean',
  },
  default_event_managed: {
    label: 'Default Event Managed',
    type: 'boolean',
  },
  default_offer_cap: {
    label: 'Default Offer Cap',
  },
  auto_placements: {
    label: 'Auto Placements',
    type: 'boolean',
  },
  scan_frequency: {
    label: 'Scan Frequency',
  },
  api_load_active_only: {
    label: 'API Load Active Only',
    type: 'boolean',
  },
  mask_source: {
    label: 'Mask Source',
    type: 'boolean',
  },
  require_secret: {
    label: 'Require Secret',
    type: 'boolean',
  },
  total_subsource_limit: {
    label: 'Total Subsource Limit',
  },
  daily_subsource_limit: {
    label: 'Daily Subsource Limit',
  },
  ip_whitelist: {
    label: 'IP Whitelist',
  },
  custom_install_event_name: {
    label: 'Custom Install Event Name',
  },
  fetch_api_override_events: {
    label: 'Fetch API Override Events',
    type: 'boolean',
  },
  fetch_api_override_tracking: {
    label: 'Fetch API Override Tracking',
    type: 'boolean',
  },
  default_require_validation: {
    label: 'Default Require Validation',
    type: 'boolean',
  },
  panel_password: {
    label: 'Panel Password',
  },
  panel_username: {
    label: 'Panel Username',
  },
  panel_url: {
    label: 'Panel URL',
  },
  group_name: {
    label: 'Group Name',
  },
  pb_params: {
    label: 'Postback Params',
  },
  tracking_link_params: {
    label: 'Tracking Link Params',
  },
  integration_params: {
    label: 'Integration Params',
  },
  sub2_blacklist: {
    label: 'Sub2 Blacklist',
  },
  sub2_blwl_mode: {
    label: 'Sub2 BLWL Mode',
    type: 'capitalize',
  },
  sub1_blacklist: {
    label: 'Sub1 Blacklist',
  },
  payable_event_name: {
    label: 'Payable Event Name',
  },
  sub2_blacklist_hash: {
    label: 'Sub2 Blacklist Hash',
    ignore: true,
  },
  sub2_blacklist_list: {
    label: 'Sub2 Blacklist List',
    type: 'list',
  },
  sub1_blacklist_hash: {
    label: 'Sub1 Blacklist Hash',
    ignore: true,
  },
  sub1_blacklist_list: {
    label: 'Sub1 Blacklist List',
    type: 'list',
  },
  ignore_freq_cap: {
    label: 'Ignore Freq Cap',
    type: 'boolean',
  },
  media_type: {
    label: 'Media Type',
    type: 'capitalize',
  },
  show_payable_only: {
    label: 'Show Payable Only',
    type: 'boolean',
  },
  report_payable_installs_only: {
    label: 'Report Payable Installs Only',
    type: 'boolean',
  },
  report_payable_events_only: {
    label: 'Report Payable Events Only',
    type: 'boolean',
  },
  default_placement_payout: {
    label: 'Default Placement Payout',
  },
  postbacks: {
    label: 'Postbacks',
    type: 'postbacks',
  },
  sub2_whitelist_hash: {
    label: 'Sub2 Whitelist Hash',
    ignore: true,
  },
  sub2_whitelist_list: {
    label: 'Sub2 Whitelist List',
    type: 'list',
  },
  restrict_timeframe_timezone: {
    label: 'Restrict Timeframe Timezone',
  },
  placement_defaults: {
    label: 'Placement Defaults',
  },
  connected_offers_weights: {
    label: 'Connected Offer Weights',
    type: 'kvMap',
  },
};

export const shouldShowField = (field) => {
  if (!activityLogFieldsMap[field]) return true;
  return !activityLogFieldsMap[field].ignore;
};

export const getFieldType = (field) => {
  if (!activityLogFieldsMap[field]) return;
  return activityLogFieldsMap[field].type;
};

export const getFieldLabel = (field) => {
  if (!activityLogFieldsMap[field]) return field;
  return activityLogFieldsMap[field].label;
};
