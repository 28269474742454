<template lang="pug">
	.row
		.col-md-6
			.widget
				.widget-body
					h4.widget-section-title Payout
					.row
						.col-sm-4
							label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.auto_payout", :width="40", :height="20", :font-size="14")
									span.lbl Automatic payout
						.col-sm-4
							.form-group
								label Payout Mode
								select.form-control(v-model="placement.payout_mode")
									option(value="fixed") Fixed
									option(value="dynamic") Dynamic


					p.clearfix &nbsp;
					div(v-if="USER.permissions['placements::RISK']")
						h4.widget-section-title Risk
						.row
							.col-sm-4
								.form-group
									label &nbsp;
									div.toggle-wrapper
										toggle-button(v-model="placement.custom_report", :width="40", :height="20", :font-size="14")
										span.lbl Override auto-report
											i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="When enabled - publisher's risk is ignored and determined by this configuration")
							.col-sm-8(v-if="placement.custom_report")
								.form-group
									label Risk Management
									.input-group
										input.form-control(type="text", placeholder="", v-model.number="placement.risk_management")
										div.input-group-append
											span.input-group-text %

						.row
							.col-sm-12
								.form-group
									label &nbsp;
									div.toggle-wrapper
										toggle-button(v-model="placement.report_offer_closed", :width="40", :height="20", :font-size="14")
										span.lbl Keep reporting even if offer is closed

							.col-sm-12
								.form-group
									label &nbsp;
									div.toggle-wrapper
										toggle-button(v-model="placement.report_on_overcap", :width="40", :height="20", :font-size="14")
										span.lbl Keep reporting even if conversion cap reached (placement or offer)
							.col-sm-6
								.form-group
									label Risk mode
									select.form-control(v-model="placement.risk_calc_mode")
										option(value="daily") Daily
										option(value="normal") From Date

							.col-sm-6(v-if="placement.risk_calc_mode === 'normal'")
								.form-group
									label Calculate risk from
									.input-group
										input.form-control(type="text", v-model="placement.risk_calc_from", placeholder="Calculate from")
										div.input-group-append
											a.input-group-text(href="javascript:void(0)", @click="setCalcFromNow()")
												i.la.la-clock-o

			os-network-targeting(v-model="osNetworkParams")

		.col-sm-6
			.widget
				//.widget-header
					h2.title More
				.widget-body
					h4.widget-section-title Filters
					.row
						.col-sm-4
							.form-group
								label Redirect Type
								select.form-control(v-model="placement.redirect_type")
									option(v-for="o in redirectTypeOptions", :value="o.v") {{ o.t }}
					.row
						.col-sm-4
							.form-group
								label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.block_iframe", :width="40", :height="20", :font-size="14")
									span.lbl Block iframe
						.col-sm-4(v-if="USER.config.blockSuspiciousHeaders")
							.form-group
								label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.block_suspicious_headers", :width="40", :height="20", :font-size="14")
									span.lbl Block Suspicious Headers
						.col-sm-4
							.form-group
								label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.block_proxies", :width="40", :height="20", :font-size="14")
									span.lbl Block proxies


					p.clearfix &nbsp;
					h4.widget-section-title Caps
					.row
						.col-sm-4
							.form-group
								label Daily Clicks Cap
								number-input(v-model="placement.daily_click_cap")
						.col-sm-4
							.form-group
								label Daily Impression Cap
								number-input(v-model="placement.daily_impression_cap")
								p.form-text(v-if="placement.daily_click_cap > 0 && placement.daily_impression_cap === 0") Paused on click cap &nbsp;
									i.la.la-info-circle(v-b-tooltip.hover.bottom, title="There is no cap on impressions, but once the click cap is reached placement will be paused")

						.col-sm-4
							.form-group
								label Daily Conversion Cap (Actual)
								number-input(v-model="placement.daily_conversion_cap")
					.row
						.col-sm-6
							.form-group
								label &nbsp;
								div.toggle-wrapper
									toggle-button(v-model="placement.use_publisher_subsource_cap", :width="40", :height="20", :font-size="14")
									span.lbl Use publisher subsource caps
						.col-sm-6
							.form-group
								label Daily Subsource Cap
								input.form-control(type="text", placeholder="100", v-model.number="placement.default_subsource_cap")

					.row
						.col-sm-6
							.form-group
								label Click pacing (Minute)
								number-input(v-model="placement.click_pacing")
								p.form-text.text-muted(v-if="!placement.click_pacing") No limit of clicks / minute
								p.form-text(v-if="placement.click_pacing") Max {{placement.click_pacing | numberCommas }} clicks / minute
						.col-sm-6
							.form-group
								label Click pacing (Hour)
								number-input(v-model="placement.click_pacing_hour")
								p.form-text.text-muted(v-if="!placement.click_pacing_hour") No limit of clicks / hour
								p.form-text(v-if="placement.click_pacing_hour") Max {{placement.click_pacing_hour | numberCommas }} clicks / hour
					.row
						.col-sm-6
							.form-group
								label Impression pacing (Minute)
								number-input(v-model="placement.impression_pacing")
								p.form-text.text-muted(v-if="!placement.impression_pacing") No limit of impressions / minute
								p.form-text(v-if="placement.impression_pacing") Max {{placement.impression_pacing | numberCommas }} impressions / minute
						.col-sm-6
							.form-group
								label Impression pacing (Hour)
								number-input(v-model="placement.impression_pacing_hour")
								p.form-text.text-muted(v-if="!placement.impression_pacing_hour") No limit of impressions / hour
								p.form-text(v-if="placement.impression_pacing_hour") Max {{placement.impression_pacing_hour | numberCommas }} impressions / hour

					p.clearfix &nbsp;
					h4.widget-section-title(v-if="(USER.config && USER.config.impressionRate) || USER.special_account_features") Misc
					.row(v-if="USER.config && USER.config.impressionRate")
						.col-sm-6
							impression-ratio(v-model="placement.impression_rate")


					// div(v-if="USER.special_account_features")
						.row
							.col-sm-6
								.form-group
									label Send % of pixel traffic to fallback
									.input-group(style="width: 90px;")
										input.form-control(style="padding: 7px 4px; text-align: center;", type="number", v-model.number="placement.smartlink_pixel", max="100", min="-1")
										.input-group-append
											.input-group-text %

			
					h4.widget-section-title Timeframe restriction
					timeframe-restriction(:params="timeframeParams", v-on:update-timeframe="updateTimeframe")

</template>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
	name: 'PlacementDefaults',
	props: {
		placement: Object,
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
	},
	data() {
		return {
			overrideName: false,
			payoutTypeOptions: Vue.ovData.general.payoutTypeOptions,
			currencyOptions: Vue.ovData.general.currencyOptions,
			redirectTypeOptions: [
				{ v: '200', t: 'HTTP 200 (After page load)' },
				{ v: '302', t: 'HTTP 302 (Default)' },
			],
			osNetworkParams: {
				carrier_whitelist: this.placement.carrier_whitelist || [],
				isp_whitelist: this.placement.isp_whitelist || [],
				should_block_wifi: this.placement.should_block_wifi || false,
				os_targeting: this.placement.os_targeting || null,
				os_targeting_version_min: this.placement.os_targeting_version_min || null,
				os_targeting_version_max: this.placement.os_targeting_version_max || null,
				os_version_blacklist: this.placement.os_version_blacklist || [],
				advanced_targeting_whitelist: this.placement.advanced_targeting_whitelist || [],
				advanced_targeting_blacklist: this.placement.advanced_targeting_blacklist || [],
			},
			timeframeParams: {
				restrict_timeframe_schedule: this.placement.restrict_timeframe_schedule,
				restrict_timeframe_timezone: this.placement.restrict_timeframe_timezone,
				restrict_timeframe: this.placement.restrict_timeframe,
			},
		};
	},
	watch: {
		osNetworkParams: {
			handler(newValue) {
				this.placement.carrier_whitelist = newValue.carrier_whitelist;
				this.placement.isp_whitelist = newValue.isp_whitelist;
				this.placement.should_block_wifi = newValue.should_block_wifi;
				this.placement.os_targeting = newValue.os_targeting;
				this.placement.os_targeting_version_min = newValue.os_targeting_version_min;
				this.placement.os_targeting_version_max = newValue.os_targeting_version_max;
				this.placement.os_version_blacklist = newValue.os_version_blacklist;
				this.placement.advanced_targeting_whitelist = newValue.advanced_targeting_whitelist;
				this.placement.advanced_targeting_blacklist = newValue.advanced_targeting_blacklist;
				this.$emit('update:placement', this.placement);
			},
			deep: true,
		},
	},
	methods: {
		setCalcFromNow() {
			this.placement.risk_calc_from = moment().format('DD/MM/YYYY HH:mm');
		},
		updateTimeframe(params) {
			this.placement.restrict_timeframe = params.restrict_timeframe;
			this.placement.restrict_timeframe_timezone = params.restrict_timeframe_timezone;
			this.placement.restrict_timeframe_schedule = params.restrict_timeframe_schedule;
			this.$emit('update:placement', this.placement);
		},
	},
};
</script>
