<template lang="pug">
.form-group
  label 
    | {{ label }}
    i.la.la-info-circle.ml-1(v-b-tooltip.hover, title="Subsource can only be selected from the table")
  .subsource-container.container.p-1.d-flex.gap-1(:class="{'large-list': value.length > 3}")
    .pill.d-flex.align-items-center.gap-2(v-for="(v, idx) of value", v-if="idx < 3", :key="v.id", :class="type")
      span [{{ v.id }}] {{ v.name }}
      span.close-icon(@click="remove(v.id)")
    .edit-pill.pill.align-items-center(@click="openListModal", v-b-tooltip.hover, title="Edit Subsource List")
      | ...({{ value.length }})
    
</template>

<script>
import SubsourceListModal from '../../views/modals/SubsourceListModal';
export default {
  name: 'SelectPlacement',
  props: {
    /** [{ id: 1, name: 'Subsource 1' }] */
    value: {
      type: Array,
      default: () => [],
    },
    /** sub1 | sub2 */
    type: {
      type: String,
      required: true,
    }
  },

  computed: {
    label() {
      if (this.type === 'sub1') return 'Subsource 1'
      return 'Subsource 2'
    }
  },

  methods: {
    /** Remove the selected subsource */
    remove(id) {
      this.$emit('input', this.value.filter((v) => v.id !== id))
    },

    openListModal() {
      console.log('subsources', this.value);
      this.$modal.show(
        SubsourceListModal,
        {
          subsources: this.value
        },
        {
          height: 'auto',
          scrollable: true,
        }
      );
    },
  }
};
</script>

<style lang="scss">
.container {
  min-height: 32px;
  min-width: 136px;
  background-color: #f2f2f2;
  cursor: pointer;
  border-radius: 5px;

  --color-sub1: rgb(128, 178, 195);
  --color-sub2: rgb(195, 192, 128);
}

.pill {
  padding: 4px 5px 4px 10px;
  border-radius: 5px;
  color: #fff;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  height: 21px;
  font-size: 12px; 
}

.sub1 {
  background: var(--color-sub1);
}

.sub2 {
  background: var(--color-sub2);
}


.close-icon {
  cursor: pointer;
  margin-left: 4px;

  &:after {
    content: "×";
    font-size: 14px;
    color: #266d4d;
    font-weight: 900;
  }
}

.subsource-container {
  .edit-pill {
    background: #aaa;
    display: none;
  }
  &.large-list {
    .edit-pill {
      display: flex;
    }
  }
}
</style>
