<template lang="pug">
  .url-input-widget
    div.result-part
      .form-group
        label.d-flex.justify-content-between
          .label
            span {{ label }}
            .small-label(v-if="mmp", style="margin-left: 5px") {{ mmp }}
          .icons
            // i.la.la-warning(v-if="!urlValidator.valid", v-b-tooltip.hover.right, :title="urlValidator.message")
            i.la.la-lock.mr-1(v-if="locked", v-b-tooltip.hover.right, title="Auto-update disabled")
        textarea.form-control(:value="selectedValue", @input="onChange", rows="4")
        .url-validator.mb-1(v-if="urlValidator.visible")
          p.clearfix
          .message
            i.la.mr-1(:class="{'la-check': urlValidator.valid, 'la-warning': !urlValidator.valid}")
            span(style="font-weight: 600") {{ urlValidator.message }} !
          .url(style="background-color: #ffc10724")
            text-highlight(:queries="urlValidator.highlight", :highlightStyle="urlValidator.style") {{ selectedValue }}
      div.text-right.d-flex.flex-column
        a.mr-2(v-if="offer && mmp === 'adjust'", href="javascript:void(0);", @click="openAdjustURLHelper()", v-b-tooltip.hover.right, title="Open Adjust URL Helper")
          i.la.la-magic
        a(href="javascript:void(0);", @click="toggleEditor()") {{ showEditor ? 'Hide Editor' : 'Show Editor' }}
        a(href="javascript:void(0);", @click="showCustomParams = !showCustomParams") {{ showCustomParams ? 'Hide Custom Params' : 'Show Custom Params' }}
    //div.show-editor-block

    div.url-parts(v-if="showEditor")
      label Base {{ adjustPbDomainLabel }}
      input.form-control(:value="parsedBaseUrl", v-on:blur="updateBase")
      table.url-params
        thead
          tr
            th Parameter
            th Value
            th.text-center(style="50px")
              button.btn.btn-primary.btn-circle.btn-sm(type="button", @click="addParam()")
                i.la.la-plus
        tbody
          tr(v-for="(p, i) in parsedParams", :class="{'danger': p.k === 'af_sub_siteid'}")
            td
              input(type="text", :value="p.k", @input="updateParam($event, p, i)")
            td
              input(type="text", :value="p.v", @input="updateValue($event, p, i)")
            td.text-center
              button.btn.btn-default.btn-circle.btn-sm(type="button", @click="removeParam(i)")
                i.la.la-times
    div.macros-part(v-if="showEditor && macros && macros.length")
      table.macros-list.table
        thead
          tr
            th Macro
            th Description
            th Aliases
        tbody
          tr(v-for="m in macros", :class="{'row-selected': m.used}", v-if="!m.hidden")
            td {{ '{' + m.param + '}' }}
            td {{ m.description }}
            td
              span(v-if="m.aliases  && m.aliases.length")
                span {{ m.aliases.join(', ') }}
                //span(v-for="a in m.aliases") {{  a }},

    div.macros-part(v-if="showCustomParams")
      .row
        .col-sm-12
          .form-group
            label Custom Parameters
            textarea.form-control(
              rows="5",
              placeholder="",
              :value="label === 'Impression URL' ? offer.impression_custom_params : offer.custom_params"
              @input="updateCustomParams"
            )


</template>
<style lang="scss">
.url-input-widget {
  border: 1px solid #ddd;
  border-radius: 5px;

  .result-part {
    padding: 15px;

    .form-group {
      margin-bottom: 0;
    }
  }

  .url-parts {
    border-top: 1px dashed #ccc;
    background: #fafafa;
    padding: 15px;
  }

  .custom-params {
    border-top: 1px dashed #ccc;
    background: #fafafa;
    padding: 15px;
  }

  .macros-part {
    border-top: 1px dashed #ccc;
    //background: #fafafa;
    padding: 15px;

    .macros-list.table {
      width: 100%;

      thead {
        tr {
          th {
            padding: 3px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 2px 3px;
          }
        }
      }
    }
  }

  i.la.la-warning {
    color: #a00;
  }
}

table.url-params {
  th {
    font-weight: normal;
    color: #ccc;
  }

  td {
    padding: 0;
  }

  input[type='text'] {
    border: 1px solid #eee;
    height: 24px;
    line-height: 22px;
  }

  .btn-default {
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 0;
    font-size: 12px;
  }

  .btn-primary {
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 0;
    font-size: 16px;
  }

  tr.danger {
    td {
      color: #a00;

      input[type='text'] {
        color: #a00;
      }
    }
  }
}
</style>
<script>
import Vue from 'vue';

/** mmp regex */

const AFRegex = /(?:https|http):\/\/(?:app|impression).appsflyer.com/;
const oneLinkRegex = /(?:https|http):\/\/(.*)onelink.me\//;
const trafficGuardRegex = /(?:https|http):\/\/(?:click|impression).trafficguard.ai\/appsflyer/;
const kochavaRegex = /(?:https|http):\/\/(?:control|imp).kochava.com/;
const adjustRegex = /(?:https|http):\/\/(?:app|s2s|view).adjust.com/;
const tenjinRegex = /(?:https|http):\/\/track.tenjin.io/;

import AdjustURLHelperModal from '../../views/modals/AdjustURLHelperModal';

export default {
  name: 'URLInput',
  props: {
    label: String,
    value: String,
    macros: {
      type: Array,
      required: false,
    },
    locked: {
      type: Boolean,
      required: false,
    },
    offer: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showEditor: false,
      showCustomParams: false,
      mmp: '',
      mmp_account_name: '',
      urlValidator: {
        highlight: '',
        style: '',
        valid: true,
        visible: false,
      },
    };
  },
  mounted() {
    if (this.value) {
      this.mmp = this.getMMP(this.value);
      this.mmp_account_name = this.getMMPAccountName(this.mmp, this.value);
      this.validateUrl(this.value);
    }
  },
  computed: {
    parsedParams() {
      try {
        let uStr = this.value;
        let parsedUrl = uStr.split('?', 2);
        let params = [];
        if (parsedUrl.length === 2) {
          let uParams = parsedUrl[1].split('&');
          for (let i = 0; i < uParams.length; i++) {
            let parsedParam = uParams[i].split('=', 2);
            params.push({ k: parsedParam[0], v: parsedParam[1] });
          }
        }
        return params;
      } catch (e) {
        return [];
      }
    },
    parsedBaseUrl() {
      try {
        return this.value;
        // let u = new URL(this.value);
        // return u.origin + u.pathname;
      } catch (e) {
        return '';
      }
    },
    selectedValue() {
      return this.value;
    },
    adjustPbDomainLabel() {
      const isAdjust = this.mmp === 'adjust';
      if (!isAdjust) {
        return '';
      }

      let pbDomain = ''
      for (const { k, v } of this.parsedParams) {
        if (k === 'event_callback' || k === 'install_callback' || k === 'rejected_install_callback') {
          pbDomain = `${new URL(decodeURIComponent(v)).origin}/`
        }
      }

      return `(Postback Domain: ${pbDomain})`
    },
  },
  methods: {
    parseUrl(url) {
      try {
        let uStr = url;
        let parsedUrl = uStr.split('?', 2);
        let params = [];
        if (parsedUrl.length === 2) {
          let uParams = parsedUrl[1].split('&');
          for (let i = 0; i < uParams.length; i++) {
            let parsedParam = uParams[i].split('=', 2);
            params.push({ k: parsedParam[0], v: parsedParam[1] });
          }
        }
        return {
          base: parsedUrl[0],
          params,
        };
      } catch (e) {
        return {
          base: url,
          params: [],
        };
      }
    },

    toggleEditor() {
      this.showEditor = !this.showEditor;
    },
    removeParam(idx) {
      let parsedUrl = this.parseUrl(this.value);
      let baseUrl = parsedUrl.base;
      baseUrl += '?';
      for (let i = 0; i < parsedUrl.params.length; i++) {
        if (i !== idx) {
          if (i > 0) {
            baseUrl += '&';
          }
          baseUrl += parsedUrl.params[i].k + '=' + parsedUrl.params[i].v;
        }
      }
      this.parseUrl(baseUrl);
      this.$emit('input', baseUrl);
      this.validateUrl(baseUrl);
    },
    addParam() {
      let newUrl = this.value;
      newUrl += (newUrl.indexOf('?') > -1 ? '&' : '?') + 'key=val';
      newUrl = newUrl.replace('&&', '&');
      this.$emit('input', newUrl);
      this.validateUrl(newUrl);
    },
    onChange(evt) {
      // console.log(evt.target.value);
      this.validateUrl(evt.target.value);
      this.$emit('input', evt.target.value);
      let mmp = this.getMMP(evt.target.value);
      this.mmp = mmp;
      this.mmp_account_name = null;
      if (mmp) {
        let mmpAccountName = this.getMMPAccountName(mmp, evt.target.value);
        this.mmp_account_name = mmpAccountName;
        this.$emit('mmp', {
          mmp,
          mmpAccountName,
        });
      }
    },

    showUrlHelper(valid, highlight, message) {
      const invalidColor = 'rgba(220,53,69,0.6)';
      const validColor = '#40dc7e6b';
      if (!this.urlValidator.valid || !valid) {
        /** show if was invalid or currently invalid */
        Vue.set(this.urlValidator, 'visible', true);
        if (valid) {
          setTimeout(() => {
            Vue.set(this.urlValidator, 'visible', false);
          }, 2000);
        }
      }
      Vue.set(this.urlValidator, 'style', {
        'background-color': valid ? validColor : invalidColor,
      });
      Vue.set(this.urlValidator, 'valid', valid);
      Vue.set(this.urlValidator, 'highlight', highlight);
      Vue.set(this.urlValidator, 'message', message || '');
    },

    validateUrl: function (url) {
      url = url.trim();

      if (url) {
        /** check for multiple "?" */
        let questionMarkMatch = url.match(new RegExp('[?]', 'g'));
        let numOfQM = questionMarkMatch ? questionMarkMatch.length : 0;
        if (numOfQM !== 1) {
          return this.showUrlHelper(false, /[?]/, 'Invalid url');
        }

        /** check for unclosed parentheses */
        let counter = 0,
          validParentheses = true;
        for (let i = 0; i < url.length; i++) {
          if (url[i] === '{') {
            counter++;
          } else if (url[i] === '}') {
            counter--;
          }
          if (counter < 0) {
            validParentheses = false;
          }
        }
        if (!validParentheses || counter !== 0) {
          return this.showUrlHelper(false, /[{,}]/, 'Unclosed parentheses');
        }

        /** check input macros are matching the supplied macros */
        const arrayOfMacros = url.match(/{[^}{]*}/g);
        let invalidMacros = [];
        if (arrayOfMacros && arrayOfMacros.length) {
          for (let macro of arrayOfMacros) {
            let macroKey = macro.substr(1, macro.length - 2);
            let matchingMacro = this.macros.find(
              (m) => m.param === macroKey || (m.aliases && m.aliases.includes(macroKey)),
            );
            if (!matchingMacro) {
              invalidMacros.push(macroKey);
            }
          }
        }
        if (invalidMacros.length) {
          return this.showUrlHelper(
            false,
            invalidMacros.map((m) => `{${m}}`),
            'Invalid macro',
          );
        }

        /** reserved params */
        if (url.indexOf('af_sub_siteid=') >= 0) {
          return this.showUrlHelper(false, ['af_sub_siteid'], 'Reserved Parameter');
        }
        if (url.indexOf('af_adset_id=') >= 0) {
          return this.showUrlHelper(false, ['af_adset_id'], 'Reserved Parameter');
        }
        if (url.indexOf('af_ad_id=') >= 0) {
          return this.showUrlHelper(false, ['af_ad_id'], 'Reserved Parameter');
        }

        this.showUrlHelper(true, /{[^}{]*}/g, 'Link is valid');
      } else {
        Vue.set(this.urlValidator, 'visible', false);
        Vue.set(this.urlValidator, 'valid', true);
        Vue.set(this.urlValidator, 'message', '');
      }
    },

    updateBase(evt) {
      try {
        let newBase = evt.target.value;
        let params = '';
        if (this.value.indexOf('?') > -1) {
          let parsed = this.value.split('?', 2);
          params = parsed[1];
        }
        if (params) {
          newBase += '?' + params;
        }
        this.$emit('input', newBase);
      } catch (e) {
        // do nothing
      }
    },
    updateParts(evt) {
      console.log(evt);
    },
    updateParam(evt, p, idx) {
      let parsedUrl = this.parseUrl(this.value);
      let baseUrl = parsedUrl.base;
      baseUrl += '?';
      for (let i = 0; i < parsedUrl.params.length; i++) {
        if (i > 0) {
          baseUrl += '&';
        }
        baseUrl +=
          (i === idx ? evt.target.value : parsedUrl.params[i].k) + '=' + parsedUrl.params[i].v;
      }
      this.$emit('input', baseUrl);
      this.validateUrl(baseUrl);
    },
    updateValue(evt, p, idx) {
      let parsedUrl = this.parseUrl(this.value);
      let baseUrl = parsedUrl.base;
      baseUrl += '?';
      for (let i = 0; i < parsedUrl.params.length; i++) {
        if (i > 0) {
          baseUrl += '&';
        }
        baseUrl +=
          parsedUrl.params[i].k + '=' + (i === idx ? evt.target.value : parsedUrl.params[i].v);
      }
      this.$emit('input', baseUrl);
      this.validateUrl(baseUrl);
    },
    updateCustomParams(evt) {
      this.$emit('customParams', {
        customParams: evt.target.value,
        type: this.label === 'Impression URL' ? 'impression' : 'click'
      });
    },

    getMMP(url) {
      if (AFRegex.test(url) || trafficGuardRegex.test(url) || oneLinkRegex.test(url)) {
        return 'appsflyer';
      } else if (adjustRegex.test(url) || url.indexOf('efse.adj.st') > -1) {
        return 'adjust';
      } else if (kochavaRegex.test(url)) {
        return 'kochava';
      } else if (tenjinRegex.test(url)) {
        return 'tenjin';
      }
      let domain;
      if (url.indexOf('//') > -1) {
        domain = url.split('/')[2];
      } else {
        domain = url.split('/')[0];
      }
      //find & remove port number
      domain = domain.split(':')[0];
      //find & remove "?"
      domain = domain.split('?')[0];
      // if (AFRegex.test(url) || trafficGuardRegex.test(url) || oneLinkRegex.test(url)) {
      // 	return 'appsflyer';
      // } else if (adjustRegex.test(url) || url.indexOf('efse.adj.st') > -1) {
      // 	return 'adjust';
      // } else if (kochavaRegex.test(url)) {
      // 	return 'kochava';
      // } else if (tenjinRegex.test(url)) {
      // 	return 'tenjin';
      // } else if (domain.indexOf('singular.net') > -1 || domain.indexOf('sng.link') > -1) {
      if (domain.indexOf('singular.net') > -1 || domain.indexOf('sng.link') > -1) {
        return 'singular';
      } else if (domain.indexOf('app.link') > -1) {
        return 'branch';
      } else if (domain.indexOf('app-measurement.com') > -1) {
        return 'tune';
      }
      return null;
    },

    getMMPAccountName(mmp, url) {
      let parsedUrl = this.parseUrl(url);
      let accountName;

      switch (mmp) {
        case 'appsflyer':
          accountName = parsedUrl.params.find((p) => p.k === 'pid');
          return accountName ? accountName.v : null;

        default:
          return null;
      }
    },

    async openAdjustURLHelper() {
      let url = this.value;
      let config = await Vue.ovReq.get('settings/getConfig');
      if (!config || !config.paths) {
        this.$ovNotify.error('config missing');
        return;
      }
      this.$modal.show(
        AdjustURLHelperModal,
        {
          offer: this.offer,
          url: url,
          pbBaseURL: config.paths.postback,
          secret: this.offer.advertiser_secret,
        },
        {
          height: '90%',
          width: '90%',
        },
        {
          'before-close': () => {
            const newUrl = localStorage.getItem('adjustUrl');
            if (newUrl) {
              localStorage.removeItem('adjustUrl');
              this.$emit('input', newUrl);
            }
          },
        },
      );
    },
  },
};
</script>
