<template lang="pug">
.row
	.col-sm-6
		.widget
			.widget-header
				h2.title Data Control
			.widget-body
				.row
					.col-sm-6
						.form-group
							label AppName Mode
							select.form-control(v-model="offer.app_name_mode")
								option(v-for="o in appnameModeOptions", :value="o.v") {{ o.t }}
					.col-sm-6
						.form-group
							label BundleID Mode
							select.form-control(v-model="offer.bundle_id_mode")
								option(v-for="o in customModeOptions", :value="o.v") {{ o.t }}
				.row
					.col-sm-6
						.form-group
							label Subsource Mode
							select.form-control(v-model="offer.subsource_mode")
								option(v-for="o in subsourceModeOptions", :value="o.v") {{ o.t }}

						div(v-if="offer.subsource_mode === 'override' || offer.subsource_mode === 'override_empty'")
							.row
								.col-sm-12(style="position:relative")
									b-form-checkbox(v-model="offer.subsource_auto_generate") Refresh Subsources &nbsp;
										small.text-muted(v-if="offer.subsource_auto_generate && offer.subsource_auto_frequency && offer.subsource_auto_total > 0 && offer.subsource_last_override", v-b-tooltip.hover.right, title="Last refresh date") {{ offer.subsource_last_override }}
									p.form-text(v-if="offer.subsource_auto_generate && offer.subsource_auto_frequency && offer.subsource_auto_total > 0")
										i.la.la-check
										| &nbsp; Auto generate {{offer.subsource_auto_total }} subsources every {{ offer.subsource_auto_frequency }} hours
									button.btn.sm-btn(v-if="offer.subsource_auto_generate", type="button", @click="editAutoGenerate = !editAutoGenerate", style="position:absolute; right: 13px; top:0; z-index:1")
										i.la(:class="{'la-pencil': !editAutoGenerate, 'la-times': editAutoGenerate}")
							.row(v-if="editAutoGenerate || (offer.subsource_auto_generate && (!offer.subsource_auto_frequency || offer.subsource_auto_total=='0'))")
								.col-sm-6
									.form-group
										label Total
										input.form-control(type="text", v-model="offer.subsource_auto_total")
								.col-sm-6
									.form-group
										label Freq
										select.form-control(v-model="offer.subsource_auto_frequency")
											option(:value="0") None
											option(:value="6") 6 hrs
											option(:value="12") 12 hrs
											option(:value="24") 24 hrs
											option(:value="48") 48 hrs
											option(:value="72") 72 hrs
											option(:value="168") Week

							//.row
								.col-sm-12
									b-form-checkbox(v-if="offer.mmp==='appsflyer'", v-model="offer.subsource_auto_optimize") Optimize Subsources
									p.form-text(v-if="offer.subsource_auto_optimize")
										i.la.la-check
										| &nbsp; Replace sources based on performance

					.col-sm-6
						.form-group
							label DeviceID Mode (IDFA/GAID)
							select.form-control(v-model="offer.deviceid_mode")
								option(v-for="o in deviceidModeOptions", :value="o.v") {{ o.t }}
				.row
					.col-sm-6
						.form-group
							label Referer Mode
							select.form-control(v-model="offer.referer_mode")
								option(v-for="o in refererModeOptions", :value="o.v") {{ o.t }}
					.col-sm-6
						.form-group
							label Distribution Format
							select.form-control(v-model="offer.distribution_format")
								option(v-for="o in distributionModeOptions", :value="o.v") {{ o.t }}


				h4.widget-section-title(v-if="USER.config && USER.config.impressionRate") Misc
				.row(v-if="USER.config && USER.config.impressionRate")
					.col-sm-12
						impression-ratio(v-model="offer.impression_rate")
				.row
					.col-sm-12
						.form-group
							label &nbsp;
							div.toggle-wrapper
								toggle-button(v-model="offer.convert_closed", :width="40", :height="20", :font-size="14")
								span.lbl Keep converting even if offer is cancelled

				h3.widget-section-title Additional Filters
				.row
					.col-sm-6
						.form-group
							b-form-checkbox(v-model="offer.block_proxies") Block Proxies
						.form-group
							b-form-checkbox(v-model="offer.block_iframe") Block Iframes
					.col-sm-6
						.form-group
							b-form-checkbox(v-model="offer.block_pixels") Block Pixels
						.form-group
							b-form-checkbox(v-model="offer.normalize_bundleid") Normalize BundleID (remove "id")

		.widget
			.widget-header
				h2.title GEO
			.widget-body
				b-form-checkbox(v-model="offer.lock_geo_targeting") Lock geo targeting

	.col-sm-6
		.widget
			.widget-header
				h2.title Caps
			.widget-body
				h3.widget-section-title Click & Conversion

				b-modal#view-records(title="Update all created offers?", @ok="updateTimezone", centered, v-if="updateTimezone")
					p.mb-0 Are you sure you want to update all created offers of this advertiser to the new timezone?
					p.mb-0 This will update all offers that have been created in the past to the new timezone.

				.row
					.col-sm-6
						.form-group
							label Offer timezone
							div
								select.form-control(v-model="offer.timezone")
									option(v-for="o in timezones", :value="o.v") {{ o.t }}
					.col-sm-6
						.form-group(v-if="updateTimezone")
							label.d-block Update all created offers to selected timezone
							button.btn.btn-sm.btn-primary(type="button", v-b-modal.view-records) Update
				.form-group
					b-form-checkbox(v-model="offer.lock_conversion_caps") Lock conversion caps
					b-form-checkbox(v-model="offer.lock_caps") Lock Click/Impression caps
					b-form-checkbox(v-model="offer.hide_caps") Hide caps
				.row
					.col-sm-6
						.form-group
							label Daily Click Cap
								i.la.la-lock.ml-1(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
								i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
							number-input(v-model="offer.daily_click_cap")
					.col-sm-6
						.form-group
							label Daily Impression Cap
								i.la.la-lock.ml-1(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
								i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
							number-input(v-model="offer.daily_impression_cap")
							p.form-text(v-if="offer.daily_click_cap > 0 && offer.daily_impression_cap === 0") Paused on click cap &nbsp;
								i.la.la-info-circle(v-b-tooltip.hover.bottom, title="There is no cap on impressions, but once the click cap is reached offer will be paused")
				.row
					.col-sm-6
						.form-group
							label Daily Conversion Cap
								i.la.la-lock.ml-1(v-if="offer.lock_conversion_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
								i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
							number-input(v-model="offer.daily_conversion_cap")
					.col-sm-6
						.form-group
							label Weekly Conversion Cap
								i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Monday to Sunday")
								i.la.la-lock.ml-1(v-if="offer.lock_conversion_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
								i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
							number-input(v-model="offer.weekly_conversion_cap")

				h3.widget-section-title Budget
				.row
					.col-sm-6
						.form-group
							label Monthly Budget
							number-input(v-model="offer.monthly_budget", prefix="$ ", fraction="2")
						.form-group(v-if="offer.id && (offer.daily_budget || offer.monthly_budget)")
							label Used
							b-progress(:value="offer.monthly_budget > 0 ? offer.used_monthly_budget : 0", :max="offer.monthly_budget", show-progress, animated, height="2rem")

					.col-sm-6
						.form-group
							label Daily Budget
							number-input(v-model="offer.daily_budget", prefix="$ ", fraction="2")
						.form-group(v-if="offer.id && (offer.daily_budget || offer.monthly_budget)")
							label Used
							b-progress(:value="offer.daily_budget > 0 ? offer.used_daily_budget : 0", :max="offer.daily_budget", show-progress, animated, height="2rem")

				h3.widget-section-title Click Pacing
				.row
					.col-sm-6
						.form-group
							label Minute
							number-input(v-model="offer.click_pacing")
							p.form-text.text-muted(v-if="!offer.click_pacing") No limit of clicks / minute
							p.form-text(v-if="offer.click_pacing") Max {{offer.click_pacing | numberCommas }} clicks / minute
					.col-sm-6
						.form-group
							label Hour
							number-input(v-model="offer.click_pacing_hour")
							p.form-text.text-muted(v-if="!offer.click_pacing_hour") No limit of clicks / hour
							p.form-text(v-if="offer.click_pacing_hour") Max {{offer.click_pacing_hour | numberCommas }} clicks / hour
				h3.widget-section-title Impression Pacing
				.row
					.col-sm-6
						.form-group
							label Minute
							number-input(v-model="offer.impression_pacing")
							p.form-text.text-muted(v-if="!offer.impression_pacing") No limit of impressions / minute
							p.form-text(v-if="offer.impression_pacing") Max {{offer.impression_pacing | numberCommas }} impressions / minute
					.col-sm-6
						.form-group
							label Hour
							number-input(v-model="offer.impression_pacing_hour")
							p.form-text.text-muted(v-if="!offer.impression_pacing_hour") No limit of impressions / hour
							p.form-text(v-if="offer.impression_pacing_hour") Max {{offer.impression_pacing_hour | numberCommas }} impressions / hour


				h3.widget-section-title Frequency Cap
				frequency-cap(v-model="offer.frequency_cap_params")
</template>
<script>
import { timezones } from '../lib/datetime';
export default {
	name: 'OfferDefaults',
	props: {
		offer: Object,
		updateTimezone: Function,
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
	},
	data() {
		return {
			editAutoGenerate: false,

			timezones,

			refererModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty referer' },
				{ v: 'block_nonempty', t: 'Block Clicks with referer' },
			],
			subsourceModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
				{ v: 'encode', t: 'Encode' },
			],
			customModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
			],
			appnameModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
				{ v: 'override_invalid', t: 'Override Invalid' },
			],
			deviceidModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
			],
			distributionModeOptions: [
				{ v: 'evenly', t: 'Evenly' },
				{ v: 'alternate_weight', t: 'Alternate Weight' },
			],
		};
	},
};
</script>