import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import VueProgressBar from 'vue-progressbar';

Vue.use(VueProgressBar, {
  // color: 'rgb(143, 255, 199)',
  color: '#ffb000',
  failedColor: '#6e0000',
  transition: { speed: '1.5s', opacity: '0.6s', termination: 400 },
  height: '2px',
});

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './styles/style.scss';

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);
import Editor from './components/Editor';
Vue.component('editor', Editor);

Vue.use(BootstrapVue);

import VueResource from 'vue-resource';
Vue.use(VueResource);

import OvReq from './plugins/ov-req';
Vue.use(OvReq);

import OvNotify from './plugins/ov-notify';
Vue.use(OvNotify);

import OvData from './plugins/ov-data';
Vue.use(OvData);

import FileList from './components/form/FileList';
Vue.component('file-list', FileList);

import DocList from './components/form/DocList';
Vue.component('doc-list', DocList);

import TimeframeRestriction from './components/form/TimeframeRestriction';
Vue.component('timeframe-restriction', TimeframeRestriction);

import GeoTargeting from './components/form/GeoTargeting';
Vue.component('geo-targeting', GeoTargeting);

import OsTargetingVersionBlacklist from './components/form/OsTargetingVersionBlacklist';
Vue.component('os-targeting-version-blacklist', OsTargetingVersionBlacklist);

import OsTargetingVersionBlacklistObject from './components/form/OsTargetingVersionBlacklistObject';
Vue.component('os-targeting-version-blacklist-object', OsTargetingVersionBlacklistObject);

import DeviceTypeTargeting from './components/form/DeviceTypeTargeting';
Vue.component('device-type-targeting', DeviceTypeTargeting);

import OsNetworkTargeting from './components/form/OsNetworkTargeting';
Vue.component('os-network-targeting', OsNetworkTargeting);

import FrequencyCap from './components/form/FrequencyCap';
Vue.component('frequency-cap', FrequencyCap);

import NewFeature from './components/form/NewFeature';
Vue.component('new-feature', NewFeature);

import PasswordInput from './components/form/PasswordInput';
Vue.component('password-input', PasswordInput);

import ValidatedInput from './components/form/ValidatedInput';
Vue.component('validated-input', ValidatedInput);

import PlacementDefaults from './components/PlacementDefaults';
Vue.component('placement-defaults', PlacementDefaults);

import OfferDefaults from './components/OfferDefaults';
Vue.component('offer-defaults', OfferDefaults);

import OfferPausedReason from './components/OfferPausedReason';
Vue.component('offer-paused-reason', OfferPausedReason);

import MmpIcon from './components/MmpIcon';
Vue.component('mmp-icon', MmpIcon);

import Filters from './components/Filters';
Vue.component('filters', Filters);

import ActivityLogTable from './components/ActivityLogTable.vue';
Vue.component('activity-log-table', ActivityLogTable);
import ActivityLogTablePipe from './components/ActivityLogTablePipe/ActivityLogTablePipe.vue';
Vue.component('activity-log-table-pipe', ActivityLogTablePipe);

import vueDebounce from 'vue-debounce';

Vue.use(vueDebounce, {
  defaultTime: '500ms',
  listenTo: ['oninput'],
});

import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, { moment });

import VModal from 'vue-js-modal';
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  height: '90%',
});

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// sort-column component
import SortColumn from './components/SortColumn';
Vue.component('ov-th', SortColumn);

// entity component
import Entity from './components/Entity';
Vue.component('entity', Entity);

import Paginate from './components/Paginate';
Vue.component('paginate', Paginate);

import OvModal from './components/OvModal';
Vue.component('ov-modal', OvModal);

import QuickEdit from './components/QuickEdit';
Vue.component('quick-edit', QuickEdit);

import QuickEditSelect from './components/QuickEditSelect';
Vue.component('quick-edit-select', QuickEditSelect);

import URLInput from './components/form/URLInput';
Vue.component('url-input', URLInput);

import NumberInput from './components/form/NumberInput';
Vue.component('number-input', NumberInput);

import ImpressionRatio from './components/form/ImpressionRatio';
Vue.component('impression-ratio', ImpressionRatio);

import ImpressionRatioDisplay from './components/ImpressionRatioDisplay';
Vue.component('impression-ratio-display', ImpressionRatioDisplay);

import Priority from './components/form/Priority';
Vue.component('priority', Priority);

import ClickList from './components/form/ClickList';
Vue.component('click-list', ClickList);

import PostbacksTable from './components/PostbacksTable';
Vue.component('postbacks-table', PostbacksTable);

import TableEditableCell from './components/TableEditableCell';
Vue.component('table-editable-cell', TableEditableCell);

// advertiser-feed-modal component
// import SelectAdvertiserFeedModal from './components/SelectAdvertiserFeedModal';
// Vue.component('select-advertiser-feed-modal', SelectAdvertiserFeedModal);

// loader plugin
import Loader from './plugins/loader';
Vue.component('loading', Loader);

import DateRangePicker from 'vue2-daterange-picker';
Vue.component('date-range-picker', DateRangePicker);
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import './plugins/date-format';

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.use(datePicker);

// datepicker component
import OvDateTimePicker from './components/form/OvDateTimePicker';
Vue.component('ov-date-time-picker', OvDateTimePicker);

import YearPicker from './components/form/YearPicker';
Vue.component('year-picker', YearPicker);

import VueTimepicker from 'vue2-timepicker';
Vue.component('vue-timepicker', VueTimepicker);
// Vue.use(VueTimepicker);
import 'vue2-timepicker/dist/VueTimepicker.css';

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect, {
  deselectLabel: 'x',
  selectLabel: 'v',
});
import 'vue-multiselect/dist/vue-multiselect.min.css';

import SelectPublisher from '@/components/filters-selectors/SelectPublisher';
Vue.component('select-publisher', SelectPublisher);

import SelectAdvertiser from '@/components/filters-selectors/SelectAdvertiser';
Vue.component('select-advertiser', SelectAdvertiser);

import SelectOffer from '@/components/filters-selectors/SelectOffer';
Vue.component('select-offer', SelectOffer);

import SelectPlacement from '@/components/filters-selectors/SelectPlacement';
Vue.component('select-placement', SelectPlacement);

import SelectSubsource from '@/components/filters-selectors/SelectSubsource';
Vue.component('select-subsource', SelectSubsource);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import ReportGraph from '@/components/ReportGraph';
Vue.component('report-graph', ReportGraph);

import ReportsWidget from '@/components/ReportsWidget';
Vue.component('reports-widget', ReportsWidget);

import Chip from '@/components/Chip';
Vue.component('chip', Chip);

// import LineChart from '@/components/LineChart';
// Vue.component('line-chart', LineChart);

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('countSelected', function (value) {
  if (!value) return '';
  return value.filter((i) => i._selected).length + ' / ' + value.length;
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
});

Vue.filter('numberCommas', function (value) {
  if (value && !Number.isNaN(value)) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return value;
  }
});

Vue.filter('shortNumber', function (value) {
  let number = '';
  let suffix = '';

  if (value < 1000) {
    return value; // No change for numbers lower than a thousand
  } else if (value >= 1000 && value < 1000000) {
    number = (value / 1000).toFixed(1);
    suffix = 'K';
  } else if (value >= 1000000 && value < 1000000000) {
    number = (value / 1000000).toFixed(1);
    suffix = 'M';
  } else if (value >= 1000000000) {
    number = (value / 1000000000).toFixed(1);
    suffix = 'B';
  }

  if (number.split('.')[1] === '0') {
    return number.split('.')[0] + suffix;
  }

  return number + suffix;
});

/** display to the provided decimal point, remove trailing zeros */
Vue.filter('removeTrailingZeros', function (value, decimalPoint = 2, prefix = '') {
  if (!value) return '0' + prefix;
  let num = parseFloat(value);
  if (isNaN(num)) return '' + prefix;

  return (num.toString().replace(/\.?0+$/, '').length > num.toString().split(".")[0].length ? num.toFixed(decimalPoint).replace(/\.?0+$/, '') : num.toFixed(0)) + prefix;
});

Vue.directive('rightclick', {
  bind(el, binding) {
    el.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      binding.value(event);
    });
  },
});

// import Default from "./layouts/default";
// Vue.component('default-layout', Default);
Vue.http.options.root = process.env.VUE_APP_API_BASE_URL + 'api/admin';
//'http://localhost:9440/admin/api';

let platformName = process.env.VUE_APP_PLATFORM;
Vue.prototype.$platformName = platformName;

let poweredByOvalio = process.env.VUE_APP_POWERED_BY_OVALIO;
Vue.prototype.$poweredByOvalio = poweredByOvalio === 'true';

async function init() {
  let initData = {
    user: null,
    token: null,
    config: null,
  };
  let user = localStorage.getItem('user');
  let token = localStorage.getItem('authToken');
  if (user) {
    try {
      user = JSON.parse(user);
    } catch (e) {
      user = null;
    }
  }

  if (token) {
    try {
      let resp = await Vue.http.get('user/getCurrent', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      initData.user = resp.body.data.user;
      initData.user.config = resp.body.data.config;
      initData.user.trackingDomains = resp.body.data.trackingDomains;
      initData.user.postbackDomains = resp.body.data.postbackDomains;
      initData.user.invoiceNotes = resp.body.data.invoiceNotes;
      initData.token = token;
      Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
    } catch (e) {
      user = null;
      token = null;
    }
  }

  let appStore = store(initData);
  router.beforeEach((to, from, next) => {
    console.log(appStore.state.user);
    appStore.dispatch('closeSidebar');
    if (to.name === 'login') {
      if (appStore.state.user) {
        next({
          name: 'dashboard',
        });
        return;
      }
    } else {
      if (!appStore.state.user) {
        if (to.name !== 'forgot-password' && to.name !== 'reset-password') {
          next({
            name: 'login',
          });
          return;
        }
      }
    }
    next();
  });

  new Vue({
    router,
    store: appStore,
    render: (h) => h(App),
  }).$mount('#app');
}

init();
