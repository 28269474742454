import { toDecimal } from '../lib/numbers';
import { getMetricFormula } from '../lib/statsCalculations';

/** @typedef {import('../views/offers/SupplyManagementForm/SupplyManagementForm.vue').Data} Data */
/** @typedef {import('../views/offers/SupplyManagementForm/SupplyManagementForm.vue').MediaTypeSummaryData} MediaTypeSummaryData */
/** @typedef {import('../views/offers/SupplyManagementForm/SupplyManagementForm.vue').SummaryData} SummaryData */
/** @typedef {import('../lib/statsCalculations').MetricName} MetricName */

export class OfferKpi {
  /**
   * @param {object} data
   * @param {number} [data.id]
   * @param {number} data.offer_id
   * @param {string} data.name
   * @param {MetricName} data.metric_base
   * @param {MetricName} data.metric_target
   * @param {'lt' | 'gt'} data.comparator
   * @param {number} data.value
   * @param {string} [data.created_at]
   * @param {string} [data.updated_at]
   * @param {string} data.slug
   */
  constructor(data) {
    this.id = data.id;
    this.value = data.value;
    this.comparator = data.comparator;
    this.offer_id = data.offer_id;
    this.metric_base = data.metric_base;
    this.metric_target = data.metric_target;
    this.name = data.name;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.slug = data.slug || data.name.toLowerCase().replace(/ /g, '_');
  }

  /**
   * @param {OfferKpi} data
   */
  update(data) {
    this.value = data.value;
    this.comparator = data.comparator;
    this.offer_id = data.offer_id;
    this.metric_base = data.metric_base;
    this.metric_target = data.metric_target;
    this.name = data.name;
    this.slug = data.slug || data.name.toLowerCase().replace(/ /g, '_');
  }

  /** @param {Data | MediaTypeSummaryData | SummaryData} record */
  calculateFormula(record) {
    const { metric_base, metric_target } = this;
    const predefinedBaseMetric = getMetricFormula(metric_base);
    const predefinedTargetMetric = getMetricFormula(metric_target);

    /** If we only have a base metric, return the base metric */
    if (!metric_target) {
      const result = predefinedBaseMetric(record);
      const isDecimal = result.toString().includes('.');
      return isDecimal ? result : toDecimal(result);
    }

    return toDecimal((predefinedBaseMetric(record) / predefinedTargetMetric(record)) * 100);
  }

  get sign() {
    if (this.metric_target) return '%';
    return '';
  }
}
